<template>
  <div class="preview">

    <a-space class="mb-10 iss-tempale" :size="12">
<!--   top   -->
      <a-row class="iss-message mb-16" v-if="templateValue.templateCode === 'C' || templateValue.templateCode === 'D'">
        <a-col :span="14">

          <div>
            <a-form >

              <a-row>
                <a-col :span="8">
                  <a-row class="head-message">
                    <div class="company-profile-title">
                      <a-avatar :size="70">
                        <template #icon>
                          <!--                            <UserOutlined /> -->
                          <img src="../assets/images/head-img.png" />
                        </template>
                      </a-avatar>
                    </div>
                    <div class="iss-name">关公</div>
                  </a-row>
                </a-col>

                <a-col :span="16">
                  <div class="company-profile-title">名片标题</div>
                  <div class="company-profile-title">手机 : 13572413694</div>
                  <div class="company-profile-title">公司 : 深圳易思智科技有限公司</div>
                </a-col>
              </a-row>

            </a-form>
          </div>

        </a-col>
        <div class="qrCode">
          <div class="qrcode1">
            <div  v-if="templateValue.templateCode !== 'D'">
              <img
                src="@/assets/images/qrCode.png"
                alt=""
                class="qrcode1"
              />
              <div class="detail">了解更多详情</div>
            </div>
          </div>

          <div class="ml-10">
            <div class="qrCodeTwo">
              <img
                class="qrcode2"
                src="@/assets/images/qrCode.png"
                alt=""
              />
            </div>
            <div class="detail">生成专属海报</div>
          </div>
        </div>

      </a-row>

        <a-row >
          <a-col :span="24">
            <img :src="templateValue.posterUri" alt="" class="iss-img"/>
          </a-col>
        </a-row>

<!--    bottom  -->
        <a-row class="iss-message" v-if="templateValue.templateCode === 'A'||templateValue.templateCode === 'B'">
          <a-col :span="14">
            <div>
              <a-form >
                <a-row>
                  <a-col :span="8">
                    <a-row class="head-message">
                      <div class="company-profile-title">
                        <a-avatar :size="70">
                          <template #icon>
                          <img src="../assets/images/head-img.png" />
                          </template>
                        </a-avatar>
                      </div>
                      <div class="iss-name">关公</div>
                    </a-row>
                  </a-col>

                  <a-col :span="16">
                    <div class="company-profile-title">这是卡片标题</div>
                    <div class="company-profile-title">手机 : 13572413694</div>
                    <div class="company-profile-title">公司 : 深圳易思智科技有限公司</div>
                  </a-col>
                </a-row>

              </a-form>
            </div>

          </a-col>
          <div class="qrCode">
            <div class="qrcode1">
              <div class="qrcode1" v-if="templateValue.templateCode !== 'B'">
                <img
                  src="@/assets/images/qrCode.png"
                  alt=""
                  class="qrcode1"
                />
                <div class="detail">了解更多详情</div>
              </div>

            </div>
            <div class="ml-10">
              <div class="qrCodeTwo">
                <img
                  class="qrcode2"
                  src="@/assets/images/qrCode.png"
                  alt=""
                />
              </div>
              <div class="detail">生成专属海报</div>
            </div>
          </div>
        </a-row>
    </a-space>
  </div>
</template>

<script>

import {  Row, Col, Avatar, Form } from 'ant-design-vue';

export default {
  name: 'userCard',
  components: {
    ARow: Row,
    ACol: Col,
    AAvatar: Avatar,
    AForm: Form,

  },

  props: {
    templateValue: {
      type: Object,
      default: () => ({
        posterName: '',
        posterUri: '',
        qrcodeLogo: '',
        mustShow: null,
        templateCode: '',

      }),
    },
  },

  // setup() {
  //
  //
  //   return {
  //
  //
  //   };
  // },
};
</script>

<style scoped lang="less">
.preview {
  width: 375px;
}

.qrcode1 {
  width: 70px;
  height: 70px;
  bottom: 136px;
}
.qrcode2 {
  width: 70px;
  height: 70px;
  bottom: 136px;
}
.iss-name {
  margin: auto;
}

.iss-img {
  width: 375px;
}
.iss-message {
  margin-top: 16px;
}
.qrCode {
  display: flex;
  justify-items: center;
  justify-content: space-between;
}
.detail {
  font-size: 12px;
  color: #86909C;

}


</style>
