<template>
  <div class="iss-main">
    <a-row>
      <a-col :span="18"
        ><a-space :size="30">
          <H3
            ><b>推广内容：{{ conData.promoteName }}</b></H3
          >
        </a-space>
      </a-col>
      <a-col :span="6" style="text-align: right">
        <a-space>
          <a-button @click="$router.go(-1)">返回</a-button>
        </a-space>
      </a-col>
    </a-row>
    <a-tabs
      v-model:activeKey="activeKey"
      :tabBarStyle="{
        margin: '0px',
        marginBottom: '20px',
      }"
    >
      <!--      <a-tab-pane key="1" tab="链接式推广">-->
      <!--        <H3><b>分享设置</b></H3>-->
      <!--        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">-->
      <!--          <a-form-item label="标题" v-bind="shareFormVali.validateInfos.title">-->
      <!--            &lt;!&ndash;              placeholder="如若不填写，则默认为资料名称"&ndash;&gt;-->
      <!--            <a-input v-model:value="shareForm.title" />-->
      <!--          </a-form-item>-->
      <!--          <a-form-item label="描述" v-bind="shareFormVali.validateInfos.remark">-->
      <!--            <a-textarea-->
      <!--              v-model:value="shareForm.remark"-->
      <!--              placeholder="如若不填写，则默认展示“点击查看详情”"-->
      <!--            />-->
      <!--          </a-form-item>-->
      <!--          <a-form-item-->
      <!--            label="缩略图"-->
      <!--            v-bind="shareFormVali.validateInfos.thumbnail"-->
      <!--          >-->
      <!--            <div>-->
      <!--              <a-row>-->
      <!--                <a-col :span="8">-->
      <!--                  <iss-image-upload-->
      <!--                    v-model:value="shareForm.thumbnail"-->
      <!--                    list-type="picture-card"-->
      <!--                    accept=".jpe,.jpeg,.jpg,.png"-->
      <!--                  />-->
      <!--                </a-col>-->
      <!--                <a-col :span="16">-->
      <!--                  &lt;!&ndash;                  <span style="color: #bfbfbf"&ndash;&gt;-->
      <!--                  &lt;!&ndash;                    >默认展示资料中合适尺寸的图片；若资料无图片，则展示默认缩略图</span>&ndash;&gt;-->
      <!--                </a-col>-->
      <!--              </a-row>-->
      <!--            </div>-->
      <!--          </a-form-item>-->
      <!--          <a-form-item :wrapper-col="{ span: 14, offset: 4 }">-->
      <!--            <a-button-->
      <!--              style="margin-right: 20px"-->
      <!--              type="primary"-->
      <!--              @click="hadleClickByShareSave"-->
      <!--              >保存</a-button-->
      <!--            >-->
      <!--            <a-button @click="$router.go(-1)">取消</a-button>-->
      <!--          </a-form-item>-->
      <!--        </a-form>-->
      <!--      </a-tab-pane>-->
      <a-tab-pane key="2" tab="海报推广">
        <div class="m-t">
          <grid
            ref="gridRef"
            :columns="columns"
            :code="$route.path"
            :url="url"
            :url-params="{ promoteId: conData.id }"
            :search="search"
            :btn-operation="btnOperation"
            :scroll="{ x: 900, y: gridHeight }"
            @dataChange="dataChange"
          >
            <template #posterUri="{ text }">
              <img :src="text.posterUri" width="130" />
            </template>
            <template #operation="{ record }">
              <operation :options="options" :record="record" />
            </template>
          </grid>
        </div>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      destroyOnClose
      :mask-closable="false"
      :visible="visible"
      :title="form.id ? '编辑海报' : '新增海报'"
      :width="1000"
      centered
      @ok="hadleClickBySave"
      @cancel="afterClose"
    >
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        class="ant-modal-body-height"
        ><a-row>
          <a-col :span="12">
            <a-form-item label="海报名称" v-bind="validateInfos.posterName">
              <a-input
                v-model:value="form.posterName"
                placeholder="请输入海报名称"
              />
            </a-form-item>
            <a-form-item
              label="海报图片"
              v-bind="validateInfos.posterUri"
              extra="建议上传宽高比为9:16，格式为JPG、JPEG或PNG的图片"
            >
              <iss-image-upload
                v-model:value="form.posterUri"
                :limit-size="10"
                list-type="picture-card"
                accept=".jpe,.jpeg,.jpg,.png"
              />
            </a-form-item>
            <a-form-item label="海报模板" >
              <a-select v-model:value="form.templateCode" placeholder="请选择海报模板">
                <a-select-option
                  v-for="item in templateCodeList"
                  :key="item.id"
                  :value="item.id"
                  @click="handleClickByUrlType(item)"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="卡片标题" v-bind="validateInfos.cardTips">
              <a-input
                v-model:value="form.cardTips"
                placeholder="请输入海报名称"
              />
            </a-form-item>
            <a-form-item
              label="卡片必须展示"
              extra="开启后，客户端的卡片开关隐藏，海报带个人信息的卡片必须展示"
            >
              <a-switch
                v-model:checked="form.mustShow"
                checked-children="是"
                un-checked-children="否"
              />
            </a-form-item>
            <!--            <a-form-item-->
            <!--              label="二维码内Logo"-->
            <!--              v-bind="validateInfos.qrcodeLogo"-->
            <!--              extra="请上传大小不超过1M，格式为JPG、JPEG或PNG的图片"-->
            <!--            >-->
            <!--              <iss-image-upload-->
            <!--                v-model:value="form.qrcodeLogo"-->
            <!--                isLt1MOpen-->
            <!--                list-type="picture-card"-->
            <!--                accept=".jpe,.jpeg,.jpg,.png"-->
            <!--              />-->
            <!--            </a-form-item>-->
          </a-col>
          <a-col :span="12">
            <!--            <a-form-item label="编辑二维码">-->
            <!--              <span style="color: #00000073"-->
            <!--                >点击二维码，移动鼠标调整二维码大小和位置。</span-->
            <!--              ></a-form-item-->
            <!--            >-->
            <a-form-item :wrapper-col="{ offset: 2 }">
              <iss-template-preview ref="templateRef" :templateValue="templateValue"/>

<!--              <div-->
<!--                class="img-drang"-->
<!--                :style="{ width: '355px', height: imgHeght + 'px' }"-->
<!--              >-->
<!--                <img-->
<!--                  class="qrcode1"-->
<!--                  src="../../../assets/images/qrCode.png"-->
<!--                  alt=""-->
<!--                />-->
<!--                <img-->
<!--                  class="qrcode2"-->
<!--                  src="../../../assets/images/qrCode.png"-->
<!--                  alt=""-->
<!--                />-->
<!--                <img-->
<!--                  v-if="form.posterUri"-->
<!--                  style="width: 100%; height: auto"-->
<!--                  :src="form.posterUri"-->
<!--                  ref="imgRef"-->
<!--                  @load="imgLoadHeght"-->
<!--                />-->
<!--                <div class="img-tip" v-else>-->
<!--                  <p>预览</p>-->
<!--                  <p><span>请上传海报图片</span></p>-->
<!--                </div>-->
<!--                <VueDragResize-->
<!--                  v-if="isReloadData"-->
<!--                  :isActive="false"-->
<!--                  :isDraggable="false"-->
<!--                  :isResizable="false"-->
<!--                  aspectRatio-->
<!--                  parentLimitation-->
<!--                  :w="dragQr.width"-->
<!--                  :h="dragQr.width"-->
<!--                  :x="dragQr.left"-->
<!--                  :y="dragQr.top"-->
<!--                  v-on:resizing="resize"-->
<!--                  v-on:dragging="resize"-->
<!--                >-->
<!--                  <vue-qr-->
<!--                    v-if="false"-->
<!--                    :logoSrc="form.qrcodeLogo"-->
<!--                    :text="getPosterLink"-->
<!--                    :margin="5"-->
<!--                    :size="dragQr.width"-->
<!--                  />-->
<!--                </VueDragResize>-->
<!--              </div>-->
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
// import VueQr from 'vue-qr/src';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import IssTemplatePreview from "@/components/templatePreview";
// import VueDragResize from 'vue-drag-resize/src';
import { useRouter } from 'vue-router';
import promoteApi from '@/api/promote';
import { local } from '@/utils/storage';
import { reactive, toRefs, ref, nextTick, computed } from "vue";
import IssImageUpload from '@/components/imageUpload';
import { Row, Col, Space, Form, message, Tabs, Switch, Select } from 'ant-design-vue';
export default {
  components: {
    ARow: Row,
    ACol: Col,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ASpace: Space,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
    Grid,
    // VueQr,
    Operation,
    IssImageUpload,
    // VueDragResize,
    ASelect: Select,
    ASelectOption: Select.Option,
    IssTemplatePreview,

  },
  setup() {
    const gridRef = ref();
    const router = useRouter();
    const imgRef = ref();
    const templateRef = ref();
    // const conData = computed(() => store.state.common.configData);
    const conData = local.get('configData');
    const state = reactive({
      visible: false,
      activeKey: '2',
      dragQr: { top: 460, left: 260, width: 64, height: 64 },
      imgHeght: '631',
      isReloadData: true,
      btnOperation: [],

      templateCodeList: [
        { id: 'A', name: '产品销售（双二维码在下）' },
        { id: 'B', name: '产品销售（单二维码在下）' },
        { id: 'C', name: '产品销售（双二维码在上）' },
        { id: 'D', name: '产品销售（单二维码在上）' },
      ],

    });
    const form = reactive({
      posterName: '',
      posterUri: '',
      qrcodeLogo: '',
      mustShow: true,
      templateCode: '',

    });

    const shareForm = reactive({
      title: '',
      remark: '',
      thumbnail: '',
    });
    const templateValue = computed(()=>form)

    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      posterName: [required, { max: 32, message: '不可超过32个字符' }],
      posterUri: [required],
      cardTips: [{ max: 20, message: '不可超过20个字符' }],
      // qrcodeLogo: [required],
    });
    const shareFormVali = Form.useForm(shareForm, {
      title: [required, { max: 64, message: '不可超过64个字符' }],
      thumbnail: [required],
      remark: [{ max: 30, message: '不可超过30个字符' }],
    });
    const dataChange = length => {
      if (!length) {
        state.btnOperation = [
          {
            type: 'add',
            permission: 'poster:add',
            fnClick: () => {
              resetFields(); //清空form
              Object.keys(form).map(key => delete form[key]);
              form.posterUri = '';
              state.visible = true;
            },
          },
        ];
      } else {
        state.btnOperation = [];
      }
    };
    const handleByEnabled = async (id, enabled) => {
      let total = 0;
      await promoteApi.marketingPosterPage('', { promoteId: id }).then(res => {
        total += res?.total;
      });
      await promoteApi.marketingLinkSelect('', id).then(res => {
        res && (total += 1);
      });
      enabled && (total -= 1);
      total == 0 && promoteApi.promoteUpdateEnabled('', { id, enabled });
    };

    promoteApi.marketingLinkSelect('', conData.id).then(res => {
      if (!res) return;
      Object.assign(shareForm, res);
    });

    return {
      form,
      imgRef,
      shareForm,
      validateInfos,
      shareFormVali,
      templateRef,
      templateValue,
      gridRef,
      conData,
      dataChange,
      gridHeight: document.body.clientHeight - 349,
      url: promoteApi.posterPageUrl,
      ...toRefs(state),
      columns: [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          align: 'center',
          width: 50,
          customRender: ({ index }) => `${index + 1}`,
        },
        { dataIndex: 'posterName', title: '海报名称' },
        {
          key: 'posterUri',
          title: '海报预览',
          slots: { customRender: 'posterUri' },
        },
        { dataIndex: 'createTime', title: '创建时间' },
        {
          key: 'id',
          title: '操作',
          width: 180,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: ':update',
          fnClick: record => {
            state.dragQr.height = record.qrcodeLong;
            state.dragQr.width = record.qrcodeWidth;
            state.dragQr.top = record.qrcodeY;
            state.dragQr.left = record.qrcodeX;
            Object.assign(form, { ...record });
            state.visible = true;
          },
        },
        {
          type: 'delete',
          permission: ':delete',
          fnClick: async ({ id }) => {
            promoteApi.posterDelete('', { ids: [id] }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
              handleByEnabled(conData.id, false);
            });
          },
        },
      ],
      getPosterLink: () => `预览二维码`,
      resize: newRect => {
        state.dragQr = newRect;
      },
      imgLoadHeght: () => {
        state.isReloadData = false;
        state.imgHeght = imgRef.value.height;
        nextTick(() => {
          state.isReloadData = true;
          !form.id &&
            (state.dragQr = { width: 64, top: 460, left: 260, height: 64 });
        });
      },
      afterClose: () => {
        state.visible = false;
      },
      handleClickByAdd: () => {
        state.visible = true;
      },
      hadleClickBySave: async () => {
        form.promoteId = conData.id;
        form.qrcodeLong = state.dragQr.height;
        form.qrcodeWidth = state.dragQr.width;
        form.qrcodeX = state.dragQr.left;
        form.qrcodeY = state.dragQr.top;
        nextTick(() => {
          validate().then(() => {
            promoteApi[form.id ? 'posterUpdate' : 'posterAdd']('', form).then(
              () => {
                message.success('操作成功');
                state.visible = false;
                gridRef.value.refreshGrid();
              }
            );
          });
        });
      },
      hadleClickByShareSave: () => {
        shareFormVali.validate().then(() => {
          promoteApi[shareForm.id ? 'marketingLinkUpdate' : 'marketingLinkAdd'](
            '',
            { ...shareForm, promoteId: conData.id }
          ).then(res => {
            message.success('操作成功');
            if (res) {
              Object.assign(shareForm, res);
            }
            router.go(-1);
          });
        });
      },
    };
  },
};
</script>
<style lang="less" scoped>
//.iss-main {
//  max-height: calc(100vh - 72px);
//  overflow-y: auto;
//}
.m-t {
  padding: 10px 7px;
  border-radius: 8px;
  overflow: hidden;
}
.img-drang {
  height: auto;
  border: 1px dashed #4e5969;
  position: relative;
  .img-tip {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #86909c;
  }
  .qrcode1 {
    width: 80px;
    height: 80px;
    bottom: 136px;
    right: 16px;
    position: absolute;
  }
  .qrcode2 {
    width: 80px;
    height: 80px;
    bottom: 32px;
    right: 16px;
    position: absolute;
  }
}
</style>
